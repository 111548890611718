import { cva, type VariantProps } from 'class-variance-authority'

export { default as Button } from './button.vue'

export const buttonVariants = cva(
  'flex gap-x-1.5 items-center justify-center leading-5 transition-all disabled:pointer-events-none cursor-pointer disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'text-white bg-brand-550 hover:bg-brand-650 disabled:bg-gray-200 disabled:text-gray-450',
        secondary: 'text-gray-900 bg-secondary hover:bg-secondary-25 disabled:bg-gray-100 disabled:text-gray-450',
        outline:
          'text-brand-550 border border-gray-450 bg-transparent hover:bg-gray-50 hover:text-brand-650 disabled:border-gray-200 disabled:text-gray-450',
        beige:
          'text-gray-900 bg-gray-250 hover:bg-gray-350 active:text-white active:bg-brand-550 disabled:bg-gray-200 disabled:text-gray-450',
        sand: 'text-gray-900 bg-gray-200 hover:bg-gray-350 active:text-white active:bg-brand-550 disabled:bg-gray-100 disabled:text-gray-450',
        gray: 'text-gray-900 bg-gray-50 [&>svg]:text-gray-350 hover:bg-gray-150 active:bg-gray-250 disabled:bg-gray-100 disabled:text-gray-450',
        menu: 'text-gray-1000 !font-medium bg-transparent hover:bg-gray-210 active:bg-gray-250',
        social:
          'text-gray-800 border border-gray-300 bg-white hover:bg-gray-150 hover:text-gray-900 disabled:border-gray-200 disabled:text-gray-450',
        link: '!p-0 text-gray-600 hover:text-brand-650 disabled:text-gray-450',
        'link-blue': '!p-0 text-brand-550 hover:text-brand-650 disabled:text-gray-450',
        tertiary:
          'bg-gray-350 hover:bg-gray-400 active:bg-brand-550 data-[state=open]:bg-brand-550 [&[data-state=open]>svg]:text-white disabled:bg-gray-200 disabled:text-gray-450',
        transparent: 'bg-transparent',
      },
      size: {
        xs: 'h-6 p-0 text-xs',
        sm: 'h-7 py-1 px-2 text-xs',
        md: 'h-9 py-1 px-3 text-sm',
        lg: 'h-10 py-3 px-2 text-sm',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
)

type Variants = VariantProps<typeof buttonVariants>
export interface ButtonProps {
  size?: Variants['size']
  variant?: Variants['variant']
  as?: 'button' | 'a' | 'router-link'
  rounded?: boolean
  disabled?: boolean
  loading?: boolean
  icon?: unknown
}
