export const ErrorMessage = {
  ValidationError:
    'Looks like the request contained invalid fields. Please review the details section to remedy this problem',
  AuthenticationError: 'Oops, looks like we cannot find matching credentials for this user',
  UserSuspendedError: 'Oops, looks like this user account is suspended. Please contact admin',
  AuthenticationTimeoutError:
    'Oops, looks like there have been a few too many login attempts for this account. Please wait a couple of minutes and try again.',
  IdleAuthenticationError: "Oops, looks like you've been inactive for too long and your login session has expired",
  UnauthorizedError: 'Sorry, you must be logged in to do this',
  NotFoundError: 'We could not find the requested resource',
  NotFoundOrExpiredError: 'Token may be expired or invalid',
  InvalidPasswordLength: 'Invalid Password Length',
  UnknownError:
    "A wild error appeared! Sorry, we don't know how this happened Try again or contact us if this issue persists",
  SSOConfigurationError: 'No SAML SSO enabled workspace could be found',
  SSOCertificateError: 'Failed to save SSO Identity Provider config. Please upload a valid Base64 Certificate.',
  VerifyDomainError: "Failed to verify domain Please make sure you've added the given DNS TXT record",
  UnavailableDomainError: 'The provided domain cannot be used for SAML SSO setup',
  VerifyUserError: 'Your account verification failed',
  PasswordUsedError: "You can't use the current password",
  NotAllowedError: 'You are not allowed to perform the required action.',
  ForbiddenError: 'You are not allowed to access this resource',
  InvalidRoles: 'Roles must exist in the workspace in order to assign them',
  InvalidGroups: 'Groups must exist in the workspace in order to assign them',
  InvalidResponseFormat:
    'The server processed your response but the response was different than the expected format. Please contact the responsible developer to fix this issue',
  NotImplemented: 'This feature is not yet implemented',
  PaginationError: 'There was a problem with the pagination details',
  AlreadyExistsError: 'A resource with the given identifier already exists',
  UsernameIsAlreadyUsedError: 'The chosen username is already in use',
  GroupScopeError: 'Group scope must be the same as parent group scope',
  GroupDeleteError: 'The group could not be deleted because it has child resources',
  GroupMoveError: 'A shortcut resource cannot be moved to a system group',
  NoPricingModelError: "The specified campaign doesn't have a pricing model template",
  NoPricingModelTypeError: 'The current workspace does not have a specified pricing model type',
  NoDefaultPricingModelError: 'There is no default pricing model template defined for the current workspace',
  PricingModelUpdateError: 'Only upcoming pricing models can pe updated',
  InvalidPriceError: 'Looks like one of the prices is invalid. Please review your changes',
  NoCampaignError:
    "A pricing model cannot be created for the specified organization because it's not part of any campaign.",
  InvalidAdditionalServiceError: 'Looks like one of the additional services cannot be set. Please review your changes',
  InvalidGBError: 'The specified data volume (in GB) appears to be invalid. Please review your changes',
  InvalidPackageError:
    'The specified packages interval (min-max weight) appears to be invalid. Please review your changes',
  UpdateWorkspacePricingModelError: 'The workspace pricing model type cannot be updated while being in use',
  DocumentConversionError: 'The conversion of the document failed',
  DocumentRetrievalError: 'Unable to find / access the required document',
  DocumentUploadError: 'Unable to upload the document',
  EmailAccountAlreadyUsed: 'Email account already attached',
  InvalidWorkspaceError: 'Invalid Workspace provided',
  IMAPUnkownError: 'Oops, looks like we cannot authenticate the IMAP email account',
  IMAPInvalidHostOrPortError:
    'We could not connect to the IMAP server. Please check that the host and port are correct or that the server is working.',
  SMTPInvalidHostOrPortError:
    'We could not connect to the SMTP server. Please check that the host and port are correct or that the server is working.',
  IMAPInvalidCredentialsError: 'Invalid credentials',
  IMAPRequireAppPasswordError: 'Imap require App password',
  IMAPProviderLoginDisabledError: 'Imap provider does not support the linking.',
  EWSEmailAuthenticationError: 'Oops, looks like we cannot authenticate the EWS email account',
  DocumentsNotReady: 'The documents to be processed contain at least 1 item in pending / error state',
  MissingDependencies: "The action failed to execute because some of it's dependencies are missing / failed to fetch",
  DocumentCreationError: 'Creating the document failed',
  EwsCredentialsFailedError: 'Fetch EWS Client Credentials Failed',
  WrongCredentialsError: 'Wrong credentials provided',
  EmailNotValidatedError: 'Email not verified',
  QrCodeAddressInvalidMatchError: 'Invalid Qr code address match',
  CustomerAccountNotValidatedError: 'Account not validated',
  EmailAlreadyUsedError: 'Email already used',
  EmailAlreadyInAddressBookError: 'Email already in address book',
  ContactAlreadyInAddressBookError: 'Contact already in address book',
  CustomerDataTokenUsedError: 'Customer Data Token already used',
  AddressBookAssignmentNotAllowed: 'Contacts can be created only inside groups or organisations.',
  MissingFolderError: 'Folder does not exist or item is not a folder.',
  NotSameAddressDestinationError: 'Addresses to move need to be moved in the same type of folder destionation.',
  MoveNotPermitted: 'Unable to move to the desired destination.',
  MissingEmailError: 'Email is required to invite person to workspace.',
  MissingContactInformationError: 'Contact data is missing from the assignment.',
  FallbackWorkflowEdit: 'Fallback Workflow details cannot be edited.',
  NotFolderError: 'The provided address contact is not a Folder.',
  NoFolderProvidedError: 'No folder provided.',
  NotAddressError: 'The provided address contact is not a Contact, it is most likely a Folder.',
  InvalidHandshake: 'Invalid Handshake Invite',
  NoHandshakeFound: 'There is no handshake with the sender',
  InvalidHandshakeToken: 'Please use the email address that received the invitation',
  UserIsInTheSameWorkspaceError: 'User is in the same workspace',
  HandshakeAlreadyExistsError: 'You already have a handshake with this user',
  UserHasNoIdentityError: 'Could not find the identity of the user',
  UserHasNoContactDataError: 'No contact data for user',
  AccessPolicyBadRequest: 'Access policy type must be the same as workflow type.',
  TooManyRequestsError: 'Too many requests. Please try again later.',
  ExpiredApiToken: 'The used API token has expired. Please generate another and try again.',
  FileNotAllowedError: 'The provided file is not supported.',
  DocumentSenderNotFound: 'The given document is not available',
  BodyRequried: 'The used method requires a body',
  MissingContactData: 'Missing Contact Data',
  InvalidFileType: 'Invalid file format',
  BlockedFolderActionError: 'Cannot use the blocked folder',
  InvalidFileHeaders: 'Invalid file headers',
  DuplicateWorkspaceTaxKeyError: 'Duplicate tax keys are not allowed',
  EmptyTaxKeyError: 'At least one tax key should exist',
  ContactAlreadyHasAnAccountError:
    'This user already has a nolas account. You can invite them in your External or Private folders instead.',
  InvalidEntryType: 'Invalid entry type provided',
  WorkspaceNotFound: 'The provided workspace was not found',
  AccountingExportFailed: 'Failed to export accounting data.',
  WorkflowUniqueNameError: 'This name is already used in an existing workflow and cannot be used again.',
  UnsupportedMediaType: 'The format of the provided data is unsupported.',
  WorkflowActivateError: "Can't activate a workflow with no steps",
} as const
